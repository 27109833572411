.logo {
  max-height: 50px;
}

section {
  position: relative;
  padding: 5rem 0;

  &.intro {
    padding: 5rem 0 8rem;
  }

  &.padding-sm {
    padding: 3rem 0;
  }

  &.pb0 { padding-bottom: 0; }

  @media (max-width: 991px) {
    padding: 3rem 0;
  }
}

.hero {
  min-height: 450px;
  display: flex;
  align-items: center;

  &.hero-tall {
    padding: 7rem 0;
  }
}

.section-title {
  margin-bottom: 2rem;
}

.overlay {
  background-color: rgba(28, 27, 26, 0.35);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  &.strong {
    background-color: rgba(28, 27, 26, 0.5);
  }

  &.bring-to-front {
    z-index: 2;
  }
}

.buttons {
  .button { margin: 0 8px 0 0; }
  .note { margin-top: 5px; font-size: 0.8rem; }

  @media (max-width: 991px) {
    .button {
      margin: 0 0 1rem;
      &:last-child { margin-bottom: 0; }
    }
  }
}

.social-icons { margin-bottom: 3rem; }

.social-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  font-size: 24px;
  text-align: center;
  color: white;
  transition: 200ms all;
  &:hover {
    color: #fff !important;
    text-decoration: none;
  }
}

.bg-hero-intro {
  background: no-repeat 50% 90%;
  background-size: cover;
  background-image: url(../img/acton-main/elementary_studio.jpg);
  background-position: center;
}

.bg-collage {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/collage3.jpg);
}

.bg-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/about.jpg);
}

.bg-cta-about {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-about.jpg);
}

.bg-home-frustrated {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-home-frustrated.jpg);
}

.bg-promise-home {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-promise-home.jpg);
}

.bg-cbf {
  background: no-repeat top;
  background-size: cover;
  background-image: url(../img/bg-cbf.jpg);
}

.bg-parents {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-parents.jpg);
  min-height: 350px;
}

.bg-campus {
  background: no-repeat 50% 90%;
  background-size: cover;
  background-image: url(../img/bg-campus.jpg);
}

.bg-elementary-hero {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/acton-main/elementary-kids.png);
}

.bg-parents-two {
  background: no-repeat 50% 30%;
  background-size: cover;
  background-image: url(../img/bg-parents-two.jpg);
}

.bg-registration {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-registration.jpg);
}

.bg-events {
  background: no-repeat 50% 60%;
  background-size: cover;
  background-image: url(../img/bg-event.jpg);
}

.bg-nest-hero {
  background: no-repeat 50% 70%;
  background-size: cover;
  background-image: url(../img/acton-main/early_elementary_studio.jpg);
}

.bg-middle {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-middle.jpg);
}

.bg-hybrid {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-hybrid.jpg);
}

.bg-cta-community-middle {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/bg-cta-community-middle.jpg);
}

.bg-apply-hero {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-apply-hero.jpg);
}

.bg-different {
  background: no-repeat 50% 40%;
  background-size: cover;
  background-image: url(../img/bg-different.jpg);
}

.bg-cta-community-elementary {
  background: no-repeat center;
  background-size: cover;
  background-image: url(../img/acton-main/bg-community-elementary.png);
}

.bg-bikes {
  background: no-repeat 50% 50%;
  background-size: cover;
  background-image: url(../img/bikes.jpg);
}

.bg-umbrellas {
  background: no-repeat 50% 50%;
  background-size: cover;
  min-height: 400px;
  height: 100%;
  width: 100%;
  background-image: url(../img/umbrellas.jpg);
}

.testimonial {
  font-size: 1.5rem;
  line-height: 1.7;

  .content {
    font-weight: 200;
  }

  .attribution {
    color: $grey;
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1.5rem;
  }

  &.white-letters { color: white; .attribution { color: white;} }

  @media (max-width: 991px) {
    font-size: 1.25rem;
  }
}

.play {
  max-height: 80px;
}

.signature {
  max-height: 60px;
  max-width: 180px;
}

.editorial {
  text-align: left;

  p {
    font-weight: 300;
    font-size: 18px;
    color: $black;
    line-height: 1.82;
  }
}

.box {
  box-shadow: 5px 5px 25px 0px rgba(46,61,73,0.2);
  padding: 30px;
}

.faqs-box {
  text-align: left;
  padding: 0 30px;
  margin: 3rem 0;

  .question-wrapper {
    padding: 30px 0;
    border-bottom: 1px solid rgba(black, 0.075);

    &.active .question::after { background-image: url(../img/icon-chevron-up.png); }
  }

  .question {
    color: $secondary;
    font-weight: 300;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      margin-left: auto;
      padding-left: 50px;
      display: block;
      position: relative;
      width: 100%;
      max-width: 21px;
      height: 11px;
      background-image: url(../img/icon-chevron-down.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .answer {
    display: none;
    font-size: 0.9rem;
    margin-top: 1rem;
    line-height: 1.72;
    p { color: $black; font-weight: 300; }
    p:last-child { margin: 0; }
  }
}

.team-member, .icon {
  height: 80px;
  width: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

footer {
  background-color: white;;
  padding: 5rem 0;
  border-top: 1px solid rgba(black, 0.075);

  a {
    color: $grey;
    display: block;
    margin-bottom: 1rem;
    transition: 200ms all;

    &:hover, &:focus, &:active {
      color: lighten($secondary, 15%);
      text-decoration: none;
    }
  }

  .copyright {
    font-size: 0.8rem;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
}

.contact-card {
  display: flex;
  align-items: center;
  font-weight: 300;
  img { margin-right: 1rem; }

  h5 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: $light-blue;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.18em;
  }

  p {
    margin-bottom: 0;
  }

  @media(max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.feature {
  text-align: left;
  h5 {
    color: $secondary;
    margin: 1rem 0;
    font-size: 1.4rem;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.72;
  }
}

.img-mega-wrapper {
  position: relative;
  margin-top: 40px;
  img {
    position: absolute;
    right: 0;
  }

  @media(max-width: 991px) {
    display: none;
  }
}

.img-featured {
  max-height: 450px;
}

form {
  legend {
    font-size: 1rem;
    font-weight: 500;
  }

  .form-check{
    margin-bottom: .5rem;
  }
}

.audition-image{
    margin: .25rem;
    width: 300px !important;
    height: auto !important;
  }

.done {
  color: $grey;
  text-decoration: line-through;
  i { color: $green; font-size: 1.5rem; margin-left: 0.5rem; }
}

.events {
  .event {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid rgb(208, 215, 223);

    &:first-child { padding-top: 0; }
    &:last-child { padding-bottom: 0; border-bottom: 0; }
  }

  .date {
    font-size: 1.25rem;
    color: $primary;
  }

  .time {
    color: $grey;
  }
}

.carousel.learning-outdoors {
  width: 100%;
  height: 360px;

  .carousel-inner {
    height: 300px;
  }

  .carousel-item, .carousel-tiem.active {
    .img-wrapper {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      height: 100%;
    }

    .img-wrapper img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
   }
  }

}

.bg-video {
  overflow: hidden;
  background-color: black;
  min-height: 70vh;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.underline {
  text-decoration: underline;
}
